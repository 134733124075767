<template lang="pug">
include ../pug/svg
section.block(:class="blockClasses" :style="styles")
	//-pre {{this.settings}}
	figure
		picture(:class="pictureClasses")
			img(v-if="imageSrc" :src="imageSrc" :alt="imageAlt")
		figcaption(v-if="image.figcaption") {{image.figcaption}}
</template>

<script>
export default {
	name: 'BlockImageSingleFront',
	props:['block'],
	computed: {
		settings() {
			return this.block.settings;
		},
		blockClasses() {
			let classes = [
				this.block.type,
				this.block.subtype,
			];

			if (this.settings.fullwidth) {
				classes.push('fullwidth');
			}
			
			if (this.settings.padTop) {
				classes.push(this.settings.padTop);
			}
			
			if (this.settings.padBottom) {
				classes.push(this.settings.padBottom);
			}
			
			return classes.join(' ');
		},
		styles() {
			let styles = [];

			if (this.settings.bgColor) {
				styles.push('background-color:#'+this.settings.bgColor);
			}

			return styles.join(';');
		},
		pictureClasses() {
			let classes = [
				'posx' + this.image.positionX,
				'posy' + this.image.positionY,
			];

			if (this.settings.ratio) {
				classes.push('ratio', this.settings.ratio);
			}
			
			if (this.settings.contain) {
				classes.push('contain');
			}
			
			return classes.join(' ');
		},
		image() {
			return this.block.content.images[0];
		},
		imageSrc() {
			if (this.image.media) {
				return this.image.media.mediaUrl;
			} else if (this.image.embed) {
				return this.image.embed.mediaUrl;
			}
			
			return null;
		},
		imageAlt() {
			if (this.image.media) {
				return this.image.media.seoAlt;
			} else if (this.image.embed) {
				return this.image.embed.seoAlt;
			}
			
			return null;
		},
	},
}
</script>

<style lang="scss">
</style>
