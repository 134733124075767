<template lang="pug">
include ../pug/svg
section.block(:class="blockClasses" :style="blockStyles")
	//-pre {{this.settings}}
	div.ratio(:class="block.settings.ratio")
		figure(v-for="(image, index) in images" :index="index" :image="image" :class="{active:index===currentIndex}")
			picture(:class="pictureClasses(image)")
				img(v-if="imageSrc(image)" :src="imageSrc(image)" :alt="imageAlt(image)" @load="imageChanged")
			figcaption(v-if="image.figcaption") {{image.figcaption}}
		ol
			li(v-for="(image, index) in images" :index="index" :image="image")
				button(:class="{active:index===currentIndex}" @click="currentIndex=index") Image {{index}}
</template>

<script>
export default {
	name: 'BlockImageCarouselFront',
	props:['block'],
	data() {
		return {
			currentIndex: 0,
		}
	},
	computed: {
		images() {
			return this.block.content.images;
		},
		settings() {
			return this.block.settings;
		},
		blockClasses() {
			let classes = [
				this.block.type,
				this.block.subtype,
			];
			
			if (this.settings.fullwidth) {
				classes.push('fullwidth');
			}
			
			if (this.settings.padTop) {
				classes.push(this.settings.padTop);
			}
			
			if (this.settings.padBottom) {
				classes.push(this.settings.padBottom);
			}
			
			return classes.join(' ');
		},
		blockStyles() {
			let styles = [];
			
			if (this.settings.bgColor) {
				styles.push('background-color:#'+this.settings.bgColor);
			}
			
			return styles.join(';');
		},
	},
	methods: {
		pictureClasses(image) {
			let classes = [
				'posx' + image.positionX,
				'posy' + image.positionY,
			];
			
			if (this.settings.contain) {
				classes.push('contain');
			}

			return classes.join(' ');
		},
		imageSrc(image) {
			if (image.media) {
				return image.media.mediaUrl;
			} else if (image.embed) {
				return image.embed.mediaUrl;
			}
			
			return null;
		},
		imageAlt(image) {
			if (image.media) {
				return image.media.seoAlt;
			} else if (image.embed) {
				return image.embed.seoAlt;
			}
			
			return null;
		},

	}
}
</script>

<style lang="scss">
</style>
