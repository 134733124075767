<template lang="pug">
include ../pug/svg
div.block(:class="blockClasses" :style="blockStyles")
	div
		picture.icon(v-if="settings.icon")
			span.material-symbols-rounded(:class="settings.icon.name" :style="{'color':iconColour()}") {{settings.icon.name}}
		h3(v-if="headed") {{headed}}
		h4(v-if="subheaded" :class="{first:settings.subheadedFirst}") {{subheaded}}
		p(v-if="onecolumn" v-for="p in onecolumn" v-html="p")
</template>

<script>
export default {
	name: 'BlockTextFront',
	props:['block'],
	computed: {
		headed() {
			const subtypes = ['headergroup','headed'];
			const headed = this.block.content.headed;
			return subtypes.includes(this.block.subtype) && headed ? this.brTags(headed) : null; 
		},
		subheaded() {
			const subtypes = ['headergroup','subheaded'];
			const subheaded = this.block.content.subheaded;
			return subtypes.includes(this.block.subtype) && subheaded ? this.brTags(subheaded) : null; 
		},
		onecolumn() {
			// extract paragraph text from before and within divs
			return this.block.content.onecolumn.match(/^[^<]*|(?<=<div>).*?(?=<\/div>)/g);
		},
		settings() {
			return this.block.settings;
		},
		blockClasses() {
			let classes = [
				this.block.type,
				this.block.subtype,
			];

			if (this.settings.minRatio) {
				classes.push('ratio', this.settings.minRatio);
			}

			if (this.settings.padTop) {
				classes.push(this.settings.padTop);
			}

			if (this.settings.padBottom) {
				classes.push(this.settings.padBottom);
			}

			if (this.settings.alignHorz) {
				classes.push(this.settings.alignHorz);
			}

			if (this.settings.whiteText) {
				classes.push('whitetext');
			}

			return classes.join(' ');
		},
		blockStyles() {
			let styles = [];

			if (this.settings.bgColor) {
				styles.push('background-color:#'+this.settings.bgColor);
			}

			return styles.join(';');
		},
	},
	methods: {
		brTags(content) {
			return content.replace(/(\n)/g, '<br>').replace(/<br>$/, '');
		},
		iconColour() {			
			return this.settings.icon.color ? '#' + this.settings.icon.color : null; // default
		},
	},
}
</script>

<style lang="scss">
</style>
