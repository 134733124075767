<template lang="pug">
include ../pug/svg
section.block(:class="blockClasses" :style="styles")
	//-pre {{audio}}
	div
		audio(:src="audioSrc" preload="auto" controls)
</template>

<script>
export default {
	name: 'BlockStreamAudioFront',
	props:['block'],
	computed: {
		settings() {
			return this.block.settings;
		},
		blockClasses() {
			let classes = [
				this.block.type,
				this.block.subtype,
			];
			
			if (this.settings.padTop) {
				classes.push(this.settings.padTop);
			}
			
			if (this.settings.padBottom) {
				classes.push(this.settings.padBottom);
			}
			
			return classes.join(' ');
		},
		styles() {
			let styles = [];
			
			if (this.settings.bgColor) {
				styles.push('background-color:#'+this.settings.bgColor);
			}
			
			return styles.join(';');
		},
		audio() {
			return this.block.content.audios[0];
		},
		audioSrc() {
			if (this.audio.media) {
				return this.audio.media.mediaUrl;
			} else if (this.audio.embed) {
				return this.audio.embed.mediaUrl;
			}
			
			return null;
		},
	},
}
</script>

<style lang="scss">
</style>
