<template lang="pug">
include ../pug/svg
section.block(:class="blockClasses" :style="blockStyles")
	div
		ol(v-if="block.subtype==='numbered'")
			li(v-for="(item, i) in block.content.items" :index="i" :key="'li'+i") {{item.value}}
		ul(v-else)
			li(v-for="(item, i) in block.content.items" :index="i" :key="'li'+i") {{item.value}}
	//-pre block: {{block}}
</template>

<script>
export default {
	name: 'BlockListFront',
	props:['block'],
	computed: {
		settings() {
			return this.block.settings;
		},
		blockClasses() {
			let classes = [
				this.block.type,
				this.block.subtype,
			];

			if (this.settings.minRatio) {
				classes.push('ratio', this.settings.minRatio);
			}

			if (this.settings.padTop) {
				classes.push(this.settings.padTop);
			}

			if (this.settings.padBottom) {
				classes.push(this.settings.padBottom);
			}

			if (this.settings.alignHorz) {
				classes.push(this.settings.alignHorz);
			}

			if (this.settings.whiteText) {
				classes.push('whitetext');
			}
			
			return classes.join(' ');
		},
		blockStyles() {
			let styles = [];

			if (this.settings.bgColor) {
				styles.push('background-color:#'+this.settings.bgColor);
			}

			return styles.join(';');
		},
	},
}
</script>

<style lang="scss">
</style>
