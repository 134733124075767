<template lang="pug">
include ../pug/svg
section.block(:class="classes" :style="styles")
	header {{block.content.label}}
</template>

<script>
export default {
	name: 'BlockDividerNumberedFront',
	props:['block'],
	computed: {
		settings() {
			return this.block.settings;
		},
		classes() {
			let classes = [
				this.block.type,
				this.block.subtype,
			];

			return classes.join(' ');
		},
		styles() {
			let styles = [];

			return styles.join(';');
		},
	},
}
</script>

<style lang="scss">
/*#front {
	.block.divider.numbered {
		position: relative;
		padding: 20px 0;
		header {
			z-index: 1;
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%,-50%);
			display: flex;
			align-items: center;
			justify-content: center;
			width: 60px;
			height: 60px;
			margin: auto;
			border-radius: 50%;
			color: #FFF;
			font-size: 2rem;
			font-weight: 400;
			background: $orange;
			box-shadow: 0 4px 8px 0px rgba(#000, .2);
		}
		&:after {
			content: '';
			position: absolute;
			top: 50%;
			left: 0;
			width: 100%;
			height: 1px;
//			background: $orange;
			background: $lightgrey;
		}
	}
}*/
</style>
