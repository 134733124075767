<template lang="pug">
include ../pug/svg
section.block(:class="blockClasses" :style="blockStyles")
	div(:class="{submitted:result!==null}")
		h3 {{block.content.question}}
		h4 {{block.content.heading}}
		p.points(v-if="course.examMode&&course.examShowAllocation")
			template(v-if="settings.examPoints > 1") {{terminology('course','point','singular')}} range [ 0 - {{settings.examPoints}} {{terminology('course','point','plural')}} ]
			template(v-else) {{terminology('course','point','singular')}} range [ 0 - {{settings.examPoints}} {{terminology('course','point','singular')}} ]
		ul
			li(v-for="(answer, i) in listAnswers" :index="i" :key="'li'+i" :class="{selected:isSelected(answer)}" @click="answerUpdated(answer)")
				+svg(svg-filename="iconTick" aria-hidden="true" alt="Correct icon")
				span {{answer.option}}
		div.modal.feedback(v-if="settings.feedback&&activeFeedback" :class="{active:activeFeedback}")
			h4(v-if="activeFeedback.heading")
				span(v-if="activeFeedback?.correct")
					+svg(svg-filename="iconHappy" aria-hidden="true" alt="Correct icon")
				span(v-else)
					+svg( svg-filename="iconSad" aria-hidden="true" alt="Incorrect icon")
				| {{activeFeedback.heading}}
			p(v-if="activeFeedback.body" v-for="(tag, i) in pTags(activeFeedback.body)" v-html="tag")
			button.but.pri(type="button" @click="closeFeedback()") Dismiss Feedback
		p
			button.but.sec.submit(type="button" @click="submitAnswer()" :disabled="result!==null||!selectedAnswers.length") Submit Answer
				span(v-if="result===true")
					+svg(svg-filename="iconHappy" aria-hidden="true" alt="Correct icon")
				span(v-if="result===false")
					+svg( svg-filename="iconSad" aria-hidden="true" alt="Incorrect icon")
</template>

<script>
export default {
	name: 'BlockTaskCheckboxFront',
//	props:['block'],
	props:['course','block','blockProgress'],
	emits: ['logCourseActivity','preventProgress'],
	data() {
		return {
			selectedAnswers: [],
			activeFeedback: null,
			result: null,
		}
	},
	mounted() {
		if (this.blockProgress) {
			// populate task with user answers
			for (let answer of this.blockProgress.userAnswers) {
				this.selectedAnswers.push(answer);
			}
			
			this.result = this.blockProgress.taskResult;
		}
		
		// block lesson progress if unanswered
		this.$emit('preventProgress', {
			blockId: this.block.id,
			bool: (this.result === null),
		});
	},
	computed: {
		settings() {
			return this.block.settings;
		},
		blockClasses() {
			let classes = [
				this.block.type,
				this.block.subtype,
			];
			
			if (this.settings.minRatio) {
				classes.push('ratio', this.settings.minRatio);
			}
			
			if (this.settings.padTop) {
				classes.push(this.settings.padTop);
			}
			
			if (this.settings.padBottom) {
				classes.push(this.settings.padBottom);
			}
			
			if (this.settings.alignHorz) {
				classes.push(this.settings.alignHorz);
			}
			
			if (this.settings.wide) {
				classes.push('wide');
			}
			
			return classes.join(' ');
		},
		blockStyles() {
			let styles = [];

			if (this.settings.bgColor) {
				styles.push('background-color:#'+this.settings.bgColor);
			}

			return styles.join(';');
		},
		listAnswers() {
			// remove correct answer
			let options = this.block.content.answers.map(a => {
				let b = {...a}
//				delete b.correct;
				return b;
			});

			if (this.block.settings.randomise) {
				// list answers randomly
				for (let i = options.length - 1; i > 0; i--) {
					const z = Math.floor(Math.random() * (i + 1));
					const temp = options[i];
					options[i] = options[z];
					options[z] = temp;
				}
			}

			return options;
		},
	},
	methods: {
		terminology(groupKey, termKey, varKey) {
			return this.$store.getters['gui/customTerm'](groupKey, termKey, varKey);
		},
		pTags(content) {
			let tags = content.split("\n\n");
			// remove empty
			tags = tags.filter(e => e);

			return tags.map(tag => {
				// remove spaces/newlines from start/end of string
				let str = tag.trim();
				// replace newlines with break tags
				return this.brTags(str);
			});
		},
		brTags(content) {
			// replace newlines with break tags
			return content.replace(/(\n)/g, '<br>');
		},
		isSelected(answer) {
			return (this.selectedAnswers.findIndex(a => a.option === answer.option) >= 0);
		},
		answerUpdated(answer) {
			if (this.result) {
				return;
			}

			const index = this.selectedAnswers.findIndex(a => a.option === answer.option);

			if (index >= 0) {
				if (this.block.rules.multiple) {
					this.selectedAnswers.splice(index, 1);
				}

			} else {
				if (this.block.rules.multiple) {
					this.selectedAnswers.push(answer);
				} else {
					this.selectedAnswers = [answer];
				}
			}
		},
		closeFeedback() {
			this.activeFeedback = null;
			this.$store.dispatch('gui/setTintState', false);
		},
		submitAnswer() {
			let correct = [];
			let incorrect = [];
			const correctAnswers = this.block.content.answers.filter(a => a.correct === true);

			for (const answer of this.selectedAnswers) {
				if (answer.correct) {
					correct.push(answer);
				} else {
					incorrect.push(answer);
				}
			}
			
			if (correct.length === correctAnswers.length) {
				// all correct!
				this.result = true;
				
				if (this.settings.feedback) {
					if (this.settings.feedback === 'global') {
						this.activeFeedback = {...this.block.content.feedback.correct};
						
					} else if (this.settings.feedback === 'individual') {
						this.activeFeedback = {...correct[0].feedback};
					}
					
					this.activeFeedback.correct = true;
					
					this.$store.dispatch('gui/setTintState', true);
				}
				
			} else {
				this.result = false;
				
				if (this.settings.feedback) {
					if (this.settings.feedback === 'global') {
						this.activeFeedback = {...this.block.content.feedback.incorrect};
						
					} else if (this.settings.feedback === 'individual') {
						this.activeFeedback = {...incorrect[0].feedback};
					}
					
					this.activeFeedback.correct = false;
					
					this.$store.dispatch('gui/setTintState', true);
				}				
			}

//			this.$store.dispatch('gui/setTintState', (this.activeFeedback !== null));

			// generate user activity log
			const msgResult = (this.result) ? 'correctly' : 'incorrectly';
			const msgType = (this.block.subtype === 'checkbox') ? 'multichoice' : 'single choice';
			const taskQuestion = this.block.content.question;
			const taskAnswers = this.block.content.answers.map(a => {
				let b = {...a}
				delete b.feedback;
				return b;
			});
			let userAnswers = [];
			for (const answer of this.selectedAnswers) {
				userAnswers.push(taskAnswers.findIndex(a => a.option === answer.option));
			}
			
			const activityLog = {
				activityType: this.block.type,
				activitySubtype: this.block.subtype,
				activityData: {
					logTitle: 'Answered multichoice question',
					logMessage: 'User '+msgResult+' answered the '+msgType+' question: '+taskQuestion,
//					taskMarked: this.block.rules.marked,
					taskMarked: false,
					taskQuestion: taskQuestion,
					taskAnswers: taskAnswers,
					userAnswers: userAnswers,
					taskResult: this.result,
					examPoints: this.settings.examPoints,
				},
			};

			this.$emit('logCourseActivity', activityLog);

			// remove progress prevention
			this.$emit('preventProgress', {
				blockId: this.block.id,
				bool: false,
			});
		},
	},
}
</script>

<style lang="scss">
</style>
