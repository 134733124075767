<template lang="pug">
include ../pug/svg
div#progress
	h2
		span
			button.but(type="button" @click="toggleIndex()")
				span Toggle Lesson Guide
				+svg(svg-filename="iconMenu" aria-hidden="true" alt="Menu icon")
			|{{lessonNumber}} of {{course.courseLessons.length}}
		|{{lesson.name}}
	template(v-for="(block, i) in lesson.blockData.blocks" :index="i" :key="block.id")
		component(:is="blockComponent(block)" :course="course" :block="block" :blockProgress="blockProgress(block)" @logCourseActivity="logCourseActivity" @preventProgress="preventProgress")
		//pre block: {{block}}
</template>

<script>
//import CourseService from '../services/CourseService';
//import AuthService from '../services/AuthService';
//import { Form, Field, ErrorMessage } from 'vee-validate';
//import * as yup from 'yup';
import BlockTextFront from '../components/BlockTextFront';
import BlockListFront from '../components/BlockListFront';
import BlockDividerNumberedFront from '../components/BlockDividerNumberedFront';
import BlockTaskCheckboxFront from '../components/BlockTaskCheckboxFront';
import BlockTaskFreeformFront from '../components/BlockTaskFreeformFront';
import BlockImageSingleFront from '../components/BlockImageSingleFront';
import BlockImageCarouselFront from '../components/BlockImageCarouselFront';
import BlockImageWithTextFront from '../components/BlockImageWithTextFront';
import BlockStreamVideoFront from '../components/BlockStreamVideoFront';
import BlockStreamAudioFront from '../components/BlockStreamAudioFront';

export default {
	name: 'CourseProgress',
	props: ['course','lesson','lessonProgress'],
	emits: ['preventProgress','logCourseActivity','toggleIndex'],
	components: {
		BlockTextFront,
		BlockListFront,
		BlockDividerNumberedFront,
		BlockTaskCheckboxFront,
		BlockTaskFreeformFront,
		BlockImageSingleFront,
		BlockImageCarouselFront,
		BlockImageWithTextFront,
		BlockStreamVideoFront,
		BlockStreamAudioFront,
	},
	data() {
		const blockTypeComponents = [
			{
				key: 'text',
				subtypes: [
					{ key: 'headergroup', component: 'BlockTextFront' },
					{ key: 'headed', component: 'BlockTextFront' },
					{ key: 'subheaded', component: 'BlockTextFront' },
					{ key: 'onecolumn', component: 'BlockTextFront' },
					//{ key: 'twocolumn', component: 'BlockTextFront' },
					//{ key: 'statement', component: 'BlockTextFront' },
					//{ key: 'quote', component: 'BlockTextFront' },
					//{ key: 'note', component: 'BlockTextFront' },
				],
			},
			{
				key: 'list',
				subtypes: [
					{ key: 'bulleted', component: 'BlockListFront' },
					{ key: 'numbered', component: 'BlockListFront' },
				],
			},
			{
				key: 'image',
				subtypes: [
					{ key: 'single', component: 'BlockImageSingleFront' },
					{ key: 'carousel', component: 'BlockImageCarouselFront' },
//					{ key: 'centered', component: '' },
//					{ key: 'grid', component: '' },
					{ key: 'withtext', component: 'BlockImageWithTextFront' },
					//{ key: 'overtext', component: 'BlockImageOverText' },
				],
			},
			{
				key: 'stream',
				subtypes: [
					{ key: 'video', component: 'BlockStreamVideoFront' },
					{ key: 'audio', component: 'BlockStreamAudioFront' },
				],
			},
			{
				key: 'task',
				subtypes: [
					{ key: 'radio', component: 'BlockTaskCheckboxFront' },
					{ key: 'checkbox', component: 'BlockTaskCheckboxFront' },
					{ key: 'freeform', component: 'BlockTaskFreeformFront' },
				],
			},
			{
				key: 'divider',
				subtypes: [
					{ key: 'numbered', component: 'BlockDividerNumberedFront' },
				],
			},
		];

		return {
			blockTypeComponents,
		};
	},
	computed: {
		lessonNumber() {
			return this.course.courseLessons.findIndex(l => l.id === this.lesson.id) + 1;
		},
	},
	methods: {
		blockProgress(block) {
			// check for previous task activity
			let activity = null;
			
			if (block.type === 'task') {
				// task progress blocking
				activity = this.lessonProgress.find(a => a.activityType === block.type && a.activitySubtype === block.subtype && a.taskQuestion === block.content.question) || null;
			}
			//console.log( 'activity:', activity );
			
			return activity;
		},
		toggleIndex() {
			this.$emit('toggleIndex');
		},
		preventProgress(payload) {
			payload.lessonId = this.lesson.id;
			this.$emit('preventProgress', payload);
		},
		logCourseActivity(activityLog) {
			activityLog.courseId = this.course.id;
			activityLog.lessonId = this.lesson.id;
//console.log('CourseProgress:logCourseActivity', activityLog);
			this.$emit('logCourseActivity', activityLog);
		},
		blockComponent(block) {
			const type = this.blockTypeComponents.find(t => t.key === block.type);
			if (!type) {
				return null;
			}

			const subtype = type.subtypes.find(s => s.key === block.subtype);
//console.log('subtype:',block.type, subtype);

			return subtype.component;
		},
	},
}
</script>

<style lang="scss">

</style>
