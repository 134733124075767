<template lang="pug">
include ../pug/svg
section.block(:class="blockClasses" :style="styles")
	//-pre {{this.settings}}
	div
		figure(:class="figureClasses")
			video(:src="videoSrc" preload="auto" controls controlsList="nofullscreen nodownload noremoteplayback" disablePictureInPicture)
			figcaption(v-if="figcaption") {{figcaption}}
</template>

<script>
export default {
	name: 'BlockStreamVideoFront',
	props:['block'],
	computed: {
		settings() {
			return this.block.settings;
		},
		blockClasses() {
			let classes = [
				this.block.type,
				this.block.subtype,
			];
			
			if (this.settings.fullwidth) {
				classes.push('fullwidth');
			}
			
			if (this.settings.padTop) {
				classes.push(this.settings.padTop);
			}
			
			if (this.settings.padBottom) {
				classes.push(this.settings.padBottom);
			}
			
			return classes.join(' ');
		},
		styles() {
			let styles = [];
			
			if (this.settings.bgColor) {
				styles.push('background-color:#'+this.settings.bgColor);
			}
			
			return styles.join(';');
		},
		video() {
			return this.block.content.videos[0];
		},
		videoSrc() {
			if (this.video.media) {
				return this.video.media.mediaUrl;
			} else if (this.video.embed) {
				return this.video.embed.mediaUrl;
			}
			
			return null;
		},
		figureClasses() {
			let classes = [];
			
			if (this.settings.ratio) {
				classes.push('ratio', this.settings.ratio);
			}
			
			return classes.join(' ');
		},
		figcaption() {
			return (this.settings.figcaption && this.video.figcaption) ? this.video.figcaption : null;
		},
	},
}
</script>

<style lang="scss">
</style>
