<template lang="pug">
include ../pug/svg
section.block(:class="blockClasses" :style="blockStyles")
	//-pre {{settings}}
	div(:class="divClasses")
		figure(:class="figureClasses")
			picture(:class="pictureClasses")
				img(v-if="imageSrc" :src="imageSrc" :alt="imageAlt")
			figcaption(v-if="settings.figcaption&&image.figcaption") {{image.figcaption}}
		div.txt(:class="textClasses")
			h3(v-if="settings.useHeader&&headed") {{headed}}
			h4(v-if="settings.useSubheader&&subheaded" :class="{first:settings.subheadedFirst}") {{subheaded}}
			p(v-if="onecolumn" v-for="p in onecolumn" v-html="p")
</template>

<script>
export default {
	name: 'BlockImageWithTextFront',
	props:['block'],
	computed: {
		settings() {
			return this.block.settings;
		},
		blockClasses() {
			let classes = [
				this.block.type,
				this.block.subtype,
			];
			
			if (this.block.settings.cover) {
				classes.push('cover');	
			}
			
			if (this.settings.padTop) {
				classes.push(this.settings.padTop);
			}
			
			if (this.settings.padBottom) {
				classes.push(this.settings.padBottom);
			}
			
			return classes.join(' ');
		},
		blockStyles() {
			let styles = [];

			if (this.settings.bgColor) {
				styles.push('background-color:#'+this.settings.bgColor);
			}

			return styles.join(';');
		},
		divClasses() {
			return this.settings.alignVert ? this.settings.alignVert : 'txttop';
		},
		figureClasses() {
			let classes = [
//				this.settings.ratio,
				this.settings.imageLocation,
			];
			
			return classes.join(' ');			
		},
		textClasses() {
			let classes = [
				this.settings.alignHorz,
//				this.settings.alignVert,
			];
			
			if (this.settings.whiteText) {
				classes.push('whitetext');
			}
			
			return classes.join(' ');			
		},
		pictureClasses() {
			let classes = [
				this.settings.ratio,
				'posx' + this.image.positionX,
				'posy' + this.image.positionY,
			];
			
			if (this.settings.contain) {
				classes.push('contain');
			}
			
			return classes.join(' ');
		},
		image() {
			return this.block.content.images[0];
		},
		imageSrc() {
			if (this.image.media) {
				return this.image.media.mediaUrl;
			} else if (this.image.embed) {
				return this.image.embed.mediaUrl;
			}
			
			return null;
		},
		imageAlt() {
			if (this.image.media) {
				return this.image.media.seoAlt;
			} else if (this.image.embed) {
				return this.image.embed.seoAlt;
			}
			
			return null;
		},
		headed() {
			const headed = this.block.content.headed;
			return headed ? this.brTags(headed) : null; 
		},
		subheaded() {
			const subheaded = this.block.content.subheaded;
			return subheaded ? this.brTags(subheaded) : null; 
		},
		onecolumn() {
			// extract paragraph text from before and within divs
			return this.block.content.onecolumn.match(/^[^<]*|(?<=<div>).*?(?=<\/div>)/g);
		},
	},
	methods: {
		brTags(content) {
			return content.replace(/(\n)/g, '<br>').replace(/<br>$/, '');
		},
	},}
</script>

<style lang="scss">
</style>
