<template lang="pug">
include ../pug/svg
section.block(:class="blockClasses" :style="blockStyles")
	div(:class="{submitted:!editable}")
		h3 {{block.content.question}}
		h4(v-if="settings.minWords&&settings.maxWords") Please write between {{settings.minWords}} and {{settings.maxWords}} words.
		h4(v-else-if="settings.minWords") Please write a minimum of {{settings.minWords}} words.
		h4(v-else-if="settings.maxWords") Please write a maximum of {{settings.minWords}} words.
		p.points(v-if="course.examMode&&course.examShowAllocation")
			template(v-if="settings.examPoints > 1") {{terminology('course','point','singular')}} range [ 0 - {{settings.examPoints}} {{terminology('course','point','plural')}} ]
			template(v-else) {{terminology('course','point','singular')}} range [ 0 - {{settings.examPoints}} {{terminology('course','point','singular')}} ]
		contenteditable.p(tag="div" :spellcheck="false" v-model="answer" :contenteditable="editable" :noHTML="noHtml" :noNL="noNl")
		small(v-if="settings.minWords||settings.maxWords") Words: {{wordCount}}
		p
			button.but.sec.submit(type="button" @click="submitAnswer()" :disabled="disabled") Submit Answer
				span(v-if="result!==false")
					+svg(svg-filename="iconHappy" aria-hidden="true" alt="Correct icon")
				span(v-if="result===false")
					+svg( svg-filename="iconSad" aria-hidden="true" alt="Incorrect icon")
</template>

<script>
import contenteditable from 'vue-contenteditable';

export default {
	name: 'BlockTaskFreeformFront',
	props:['course','block','blockProgress'],
	emits: ['logCourseActivity','preventProgress'],
	components: {
		contenteditable,
	},
	data() {
		return {
			result: null,
			answer: '',
			//
			editable: true,
			noHtml: false, // see notes in: submitAnswer()
			noNl: false,
		}
	},
	mounted() {
//console.log('mounted:block:', this.block);
		if (this.blockProgress) {
			// populate task with user answer
			this.answer = this.blockProgress.userAnswer;
			// prevent re-edit
			this.result = this.blockProgress.taskResult;
			this.editable = false;
		}
		
		// block lesson progress if unanswered
		this.$emit('preventProgress', {
			blockId: this.block.id,
			bool: (this.answer === ''),
		});
	},
	computed: {
		settings() {
			return this.block.settings;
		},
		multiline() {
			return (this.settings.minWords || this.settings.maxWords);
		},
		blockClasses() {
			let classes = [
				this.block.type,
				this.block.subtype,
			];
			
			if (this.settings.alignHorz) {
				classes.push(this.settings.alignHorz);
			}
			
			if (this.multiline) {
				classes.push('multiline');
			}
			
			return classes.join(' ');
		},
		blockStyles() {
			let styles = [];
			
			return styles.join(';');
		},
		wordCount() {
			let str = this.answer;
			
			str = str.replace(/&nbsp;/g, ' ');
			str = str.replace(/(<([^>]+)>)/gi, ' ');
			str = str.replace(/\n/g, ' ');
			str = str.split(' ').filter(s => s !== '');
			
			return str.length;
		},
		disabled() {
			let bool = false;
			
			if (this.settings.minWords && this.wordCount < this.settings.minWords) {
				bool = true;
			}
			
			if (this.settings.maxWords && this.wordCount > this.settings.maxWords) {
				bool = true;
			}
			
			if (this.result !== null) {
				bool = true;
			}
			
			return bool;
		},
	},
	methods: {
		terminology(groupKey, termKey, varKey) {
			return this.$store.getters['gui/customTerm'](groupKey, termKey, varKey);
		},
		submitAnswer() {
			this.result = null; // pending
			this.editable = false;
			const taskQuestion = this.block.content.question;
			
			//let taskQuestion = this.block.content.question.replace(/\n/g, ' ').trim();
			if (this.noHtml) {
				// e.g: test over two lines \nLets see what we get now!
			} else {
				// e.g: <div>test over two lines</div><div>Lets see what we get now!<br></div>
			}
			
			const activityLog = {
				activityType: this.block.type,
				activitySubtype: this.block.subtype,
				activityData: {
					logTitle: 'Answered freeform question',
					logMessage: 'User answered the freeform question: ' + taskQuestion,
//					taskMarked: this.block.rules.marked,
					taskMarked: true,
					taskQuestion: taskQuestion,
					userAnswer: this.answer,
					taskResult: this.result,
					examPoints: this.settings.examPoints,
					userPoints: null, // manually marked later
				},
			};
			
			this.$emit('logCourseActivity', activityLog);
			
			// remove progress prevention
			this.$emit('preventProgress', {
				blockId: this.block.id,
				bool: false,
			});
		},
	},
}
</script>

<style lang="scss">
</style>
